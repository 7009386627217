/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import Banner from './banner.png'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            css={{
              background: `url(${Banner}) no-repeat center bottom`,
              backgroundSize: `${1200 * .75}px ${280 * .75}px`,
              marginBottom: 16,
              maxWidth: 1200,
              minHeight: 280,
              width: '100%',
              "@media (min-width: 768px)": {
                backgroundSize: `${1200 * .85}px ${280 * .85}px`,
                minHeight: 340
              },
              "@media (min-width: 1024px)": {
                backgroundSize: '1200px 280px',
                minHeight: 380
              }
            }}
          >
            <svg
              css={{
                marginLeft: 30,
                width: 120,
                "@media (min-width: 768px)": {
                  marginLeft: 45,
                  width: 160
                }
              }}
              viewBox="0 0 160 100"
            >
              <path fill={'#F4B825'} d="M108.3,20.6" />
              <g>
                <path
                  fill={'#F4B825'}
                  d="M115.2,15.2c0.6,0,1.1,0.1,1.6,0.3l-1.6-5l-1.6,5C114.1,15.3,114.7,15.2,115.2,15.2z"
                />
                <path
                  fill={'#F4B825'}
                  d="M117.5,15.9c1,0.6,1.6,1.7,1.8,2.9l2.8-5.3L117.5,15.9z"
                />
                <path
                  fill={'#F4B825'}
                  d="M112.9,15.9l-4.6-2.4l2.8,5.3C111.3,17.6,112,16.6,112.9,15.9z"
                />
              </g>
              <path
                fill={'#F4B825'}
                d="M10.7,8v43.9c0,4.3,1.5,6,4.6,6c0.5,0,1,0.4,1,1s-0.4,1-1,1c-4.4,0-6.8-2.7-6.8-7.9V8"
              />
              <path
                fill={'#F4B825'}
                d="M22.4,26.4v20.7c0,6,1.9,11.2,11.3,11.2c9.3,0,11.3-5.1,11.3-11.2V26.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v20.7c0,6.8-2.4,13.1-13.5,13.1s-13.5-6.3-13.5-13.1V26.4c0-0.6,0.5-1.1,1.1-1.1C21.8,25.3,22.4,25.8,22.4,26.4z"
              />
              <path
                fill={'#F4B825'}
                d="M105.4,37.8v20.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V37.8c0-6-2.4-11.2-10.9-11.2c-7.7,0-10.7,5.2-10.7,11.3v20.8c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V37.8c0-6-2-11.2-10.7-11.2c-7.7,0-11,6.5-11,12.8v19.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V26.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v5.6c1-3.2,4.5-7.1,11.2-7.1c6.9,0,10.5,2.9,11.8,6.8c1-3.1,4.6-6.8,11.5-6.8C102.9,24.8,105.4,31.1,105.4,37.8z"
              />
              <path
                fill={'#F4B825'}
                d="M116.3,26.4v32.4c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V26.4c0-0.6,0.5-1.1,1.1-1.1C115.8,25.3,116.3,25.8,116.3,26.4z"
              />
              <path
                fill={'#F4B825'}
                d="M125.2,58.7V26.4c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v5.5c1.1-2.9,4.6-7.1,11.5-7.1c10.6,0,13.2,6.3,13.2,13v20.9c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1V37.8c0-6-2.1-11.1-11-11.1c-8,0-11.5,6.1-11.5,11.8v20.1c0,0.6-0.5,1.1-1.1,1.1C125.7,59.8,125.2,59.3,125.2,58.7z"
              />
              <g>
                <path
                  fill={'#4B666C'}
                  d="M9.5,92c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V69.9c0-0.3,0.2-0.5,0.5-0.5h7.2c3.5,0,6.2,2.1,6.2,6.4c0,4.2-2.7,6.4-6.2,6.4H9.5V92z M9.5,81.2h6.3c3.4,0,5.5-1.9,5.5-5.4c0-3.6-2.1-5.4-5.5-5.4H9.5V81.2z"
                />
                <path
                  fill={'#4B666C'}
                  d="M27.1,92c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V69.9c0-0.3,0.2-0.5,0.5-0.5h7.1c3.6,0,6.2,2.8,6.2,6.2c0,3.5-2.4,6.3-6.1,6.3H33c0,0,7.3,9.9,7.2,9.8c0,0,0.1,0.1,0.1,0.3c0,0.3-0.2,0.5-0.6,0.5c-0.2,0-0.3-0.1-0.5-0.3l-7.5-10.4h-4.7V92z M27.1,80.9h6.7c3,0,5-2.3,5-5.3c0-3-2.1-5.3-5.1-5.3h-6.6V80.9z"
                />
                <path
                  fill={'#4B666C'}
                  d="M42.8,80.8c0-8.1,3.9-11.9,10.5-11.9s10.5,3.9,10.5,11.9c0,8-3.9,11.9-10.5,11.9S42.8,88.8,42.8,80.8z M43.9,80.8c0,7.5,3.4,10.9,9.4,10.9s9.4-3.4,9.4-10.9c0-7.5-3.4-11-9.4-11S43.9,73.3,43.9,80.8z"
                />
                <path
                  fill={'#4B666C'}
                  d="M69.1,92c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V69.9c0-0.3,0.2-0.5,0.5-0.5h7.2c3.5,0,6.2,2.1,6.2,6.4c0,4.2-2.7,6.4-6.2,6.4h-6.6V92z M69.1,81.2h6.3c3.4,0,5.5-1.9,5.5-5.4c0-3.6-2.1-5.4-5.5-5.4h-6.3V81.2z"
                />
                <path
                  fill={'#4B666C'}
                  d="M86.2,92.2c-0.3,0-0.5-0.2-0.5-0.5V69.9c0-0.3,0.2-0.5,0.5-0.5h11.5c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-11v9.9h8.6c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5h-8.6v10.1h11.4c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5H86.2z"
                />
                <path
                  fill={'#4B666C'}
                  d="M103.4,92c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V69.9c0-0.3,0.2-0.5,0.5-0.5h7.1c3.6,0,6.2,2.8,6.2,6.2c0,3.5-2.4,6.3-6.1,6.3h-0.8c0,0,7.3,9.9,7.2,9.8c0,0,0.1,0.1,0.1,0.3c0,0.3-0.2,0.5-0.6,0.5c-0.2,0-0.3-0.1-0.5-0.3L108,81.8h-4.7V92z M103.4,80.9h6.7c3,0,5-2.3,5-5.3c0-3-2.1-5.3-5.1-5.3h-6.6V80.9z"
                />
                <path
                  fill={'#4B666C'}
                  d="M118.3,70.3c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5h15.2c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-7V92c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5V70.3H118.3z"
                />
                <path
                  fill={'#4B666C'}
                  d="M143.6,92V81.4L136.3,70c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.2-0.6,0.5-0.6c0.2,0,0.4,0.1,0.5,0.3l6.9,11l6.9-11c0.1-0.2,0.3-0.3,0.5-0.3c0.3,0,0.6,0.2,0.6,0.6c0,0.1,0,0.2-0.1,0.3l-7.3,11.4V92c0,0.3-0.2,0.5-0.5,0.5S143.6,92.3,143.6,92z"
                />
              </g>
            </svg>
            <h1>Lumin Property</h1>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
